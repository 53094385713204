import { GeneralLocaleTypes } from '../locales.types'

const generalLocaleNn: GeneralLocaleTypes = {
  username: 'Brukarnamn',
  imageAlt: {},
  status: {
    error: {
      somethingWentWrong: 'Hmm... Noe gjekk gale!',
      sessionError: 'Kan ikke bekrefte økten, prøv igjen senere...',
      failedToLogin:
        'Klarte ikkje å logge inn. Ver venleg og prøv igjen seinare.',
      underMaintenance: 'Under vedlikehald. Ver venleg og prøv igjen seinare.',
      failWriteToLocalStorage:
        'Kan ikkje lagre data. Ver venleg og sjekk personverninnstillingane dine eller tilgjengeleg diskplass og prøv på nytt.',
      sessionExpired: 'Økta er utgått. Ver venleg og logg inn igjen.'
    }
  },
  actions: {
    back: 'Tilbake',
    cancel: 'Avbryt',
    moveOn: 'Gå vidare',
    remove: 'Fjern',
    next: 'Neste',
    save: 'Lagre {{ addOn }}',
    update: 'Oppdater {{ addOn }}',
    select: 'Velg',
    confirm: 'Bekreft',
    close: 'Lukk',
    revoke: 'Fjern',
    edit: 'Rediger',
    delete: 'Slett'
  },
  or: 'Eller'
}

export default generalLocaleNn
