import { api } from 'services/api/api'
import LocalStorageService from 'utils/localStorageService'

const checkUserProfile = async (token: string) => {
  try {
    const response = await api.get(`/games/user/profile/id`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return response.data
  } catch (error) {
    LocalStorageService.removeItem('session')
    window.location.reload()
    throw error
  }
}

export default {
  get: checkUserProfile
}
