import { GeneralLocaleTypes } from '../locales.types'

const generalLocalePt: GeneralLocaleTypes = {
  username: 'Nome de usuário',
  imageAlt: {},
  status: {
    error: {
      somethingWentWrong: 'Algo deu errado',
      sessionError: 'Indisponível, por favor tente mais tarde...',
      failedToLogin:
        'Falha ao fazer login. Por favor, tente novamente mais tarde.',
      underMaintenance: 'Em manutenção. Por favor, tente novamente mais tarde.',
      failWriteToLocalStorage:
        'Não foi possível salvar os dados. Verifique suas configurações de privacidade ou o espaço disponível em disco e tente novamente',
      sessionExpired: 'Økta er utgått. Ver venleg og logg inn igjen.'
    }
  },
  actions: {
    back: 'Voltar',
    cancel: 'Cancelar',
    moveOn: 'Avançar',
    edit: 'Editar',
    delete: 'Apagar',
    remove: 'Remover',
    next: 'Próximo',
    save: 'Salvar {{ addOn }}',
    update: 'Atualizar {{ addOn }}',
    select: 'Selecionar',
    confirm: 'Confirmar',
    revoke: 'Revogar',
    close: 'Fechar'
  },
  or: 'ou'
}

export default generalLocalePt
