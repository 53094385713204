import numetryLogo from 'assets/images/company/numetry-logo-en.png'
import SignInPassword from 'assets/audios/en/EN-signin-password.mp3'
import SignInUsername from 'assets/audios/en/EN-signin-username.mp3'
import SignUpUsername from 'assets/audios/en/EN-signup-username.mp3'
import SignUpPassword from 'assets/audios/en/EN-signup-password.mp3'
import SignUpReview from 'assets/audios/en/EN-signup-review.mp3'
import Character from 'assets/audios/en/EN-choose-your-character.mp3'

import { PagesLocaleTypes } from '../locales.types'

const pageLocaleTypesPt: PagesLocaleTypes = {
  landing: {
    imPlayingFrom: 'No momento estou jogando Numetry…',
    modality: {
      school: 'Na Escola',
      freeTime: 'Fora da Escola'
    }
  },
  home: {
    logo: numetryLogo,
    newUser: 'Novo Usuário',
    signIn: 'Entrar',
    haveYouPlayed: 'Você já jogou Numetry?',
    logInWithFeide: 'Faça login com Feide',
    logInWithoutFeide: 'Faça login sem Feide',
    createNewUser: 'Criar um novo usuário',
    doesNotHaveFeide: `Sua escola não possui login do Feide para o Numetry?\n\n Contate-nos em [{{ email }}](mailto:{{ email }}?subject=Acesso%20com%20Feide) para começar gratuitamente hoje.`,
    or: 'ou',
    footer: {
      aGameBy: 'Um jogo da'
    }
  },
  unsuportedDevice: {
    title:
      'Numetry atualmente só pode ser jogado em PC/Chromebook/Mac ou através do nosso aplicativo para iPad.',
    button: 'Abra o Numetry no aplicativo do iPad.',
    footer:
      'Se você não tem o aplicativo. <0>Baixe-o na loja de aplicativos.</0>'
  },
  logout: {
    loggingOut: 'Saindo...'
  },
  auth: {
    validationError: {
      requiredField: 'Campo necessário'
    },
    redirectToGame: {
      redirectingToGame: 'Redirecionando para o jogo...',
      continueAs: 'Continuar como:',
      yes: 'Sim',
      noLogOut: 'Não, logout'
    },
    character: {
      contextButton: {
        playNumetry: 'Jogar Numetry'
      },
      title: 'Escolha seu personagem.',
      audio: Character,
      input: {
        placeholder: 'Coloque um nome no seu avatar...'
      },
      buttons: {
        save: {
          value: 'Salvar'
        },
        clearName: {
          value: 'Apagar o nome!'
        },
        change: {
          value: 'Mudar'
        }
      }
    },
    signUp: {
      usernameInUse: 'Nome de usuário já existe, escolha outro.',
      usernameCannotBeUsed: 'Este nome de usuário não pode ser utilizado.',
      usernameStep: {
        audio: SignUpUsername,
        beforeYouCanStart:
          'Olá! Antes de começar a jogar, você precisa criar um usuário.',
        usernameAlreadyTaken: 'Nome de usuário já existe. Sugestões:',
        username: 'Nome de usuário'
      },
      passwordStep: {
        audio: SignUpPassword,
        alsoCreatePassword: 'Você precisa criar uma senha.',
        password: 'Senha',
        passwordRepeat: 'Coloque a senha novamente:',
        inputPlaceholder: 'Senha com {{ minPasswordLength }} caracteres',
        minimumPasswordCharacters:
          ' Sua senha precisa conter pelo menos {{ minPasswordLength }} caracteres.',
        passwordNotEqual: 'A senha está incorreta.',
        togglePasswordVisibility: 'Ajustar a visibilidade da senha'
      },
      reviewStep: {
        audio: SignUpReview,
        greatRememberThis:
          'Legal! Você não pode esquecer essas informações. Anote!',
        username: 'Nome de usuário:',
        password: 'Senha:',
        iveWrittenItDown: 'Eu já anotei.'
      }
    },
    signIn: {
      usernameStep: {
        audio: SignInUsername,
        enterYourUsername: 'Insira seu nome de usuário para entrar.',
        enterYourUsernameInput: 'Insira seu nome de usuário',
        notPlayedBefore: 'Nunca jogou antes?',
        createNewUser: 'Crie um novo usuário',
        usernameError: 'Esse nome de usuário não existe!'
      },
      passwordStep: {
        audio: SignInPassword,
        youMustEnterYourPassword: 'Você precisa colocar também a sua senha.',
        passwordWithMinCharacter:
          'Senha com {{ minPasswordLength }} caracteres',
        passwordError: 'Nome de usuário ou senha inválidos.'
      }
    }
  },
  municipality: {
    getStartedWithFeide: 'Comece com Feide gratuitamente'
  }
}

export default pageLocaleTypesPt
