import numetryLogo from 'assets/images/company/numetry-logo-en.png'
import SignInPassword from 'assets/audios/en/EN-signin-password.mp3'
import SignInUsername from 'assets/audios/en/EN-signin-username.mp3'
import SignUpUsername from 'assets/audios/en/EN-signup-username.mp3'
import SignUpPassword from 'assets/audios/en/EN-signup-password.mp3'
import SignUpReview from 'assets/audios/en/EN-signup-review.mp3'
import Character from 'assets/audios/en/EN-choose-your-character.mp3'

export default {
  landing: {
    imPlayingFrom: `Right now, I'm playing Numetry at…`,
    modality: {
      school: 'School',
      freeTime: 'Free time'
    }
  },
  home: {
    logo: numetryLogo,
    newUser: 'New User',
    signIn: 'Sign In',
    haveYouPlayed: 'Have you played Numetry before?',
    logInWithFeide: 'Log in with Feide',
    logInWithoutFeide: 'Log in without Feide',
    createNewUser: 'Create a new user',
    doesNotHaveFeide: `Does your school not have a Feide login in Numetry?\n\n Contact us at [{{ email }}](mailto:{{ email }}?subject=Access%20to%20Feide) to get started for free today.`,
    or: 'or',
    footer: {
      aGameBy: 'A game by'
    }
  },
  unsuportedDevice: {
    title:
      'Numetry can currently only be played on PC/Chromebook/Mac or through our iPad app.',
    button: 'Open Numetry in the iPad app.',
    footer: 'If you don’t have the app. <0>Get it from the app store.</0>'
  },
  logout: {
    loggingOut: 'Logging out...'
  },
  auth: {
    validationError: {
      requiredField: 'Required field'
    },
    redirectToGame: {
      redirectingToGame: 'Redirecting to game...',
      continueAs: 'Continue as:',
      yes: 'yes',
      noLogOut: 'No, log out'
    },
    character: {
      contextButton: {
        playNumetry: 'Play Numetry'
      },
      title: 'Choose your character.',
      audio: Character,
      input: {
        placeholder: 'Give your avatar name...'
      },
      buttons: {
        save: {
          value: 'Save'
        },
        clearName: {
          value: 'Clear name!'
        },
        change: {
          value: 'Change'
        }
      }
    },
    signUp: {
      usernameInUse: 'Username is already in use, choose another one.',
      usernameCannotBeUsed: `This username cannot be used.`,
      usernameStep: {
        audio: SignUpUsername,
        beforeYouCanStart:
          'Hi! Before you can start playing, you must create a user.',
        usernameAlreadyTaken: 'Username already taken. Suggestions:',
        username: 'Username'
      },
      passwordStep: {
        audio: SignUpPassword,
        alsoCreatePassword: 'You must also create a password.',
        password: 'Password',
        passwordRepeat: 'Same password as above:',
        inputPlaceholder:
          'Password with at least {{ minPasswordLength }} characters',
        minimumPasswordCharacters:
          'Your password needs to contain at least {{ minPasswordLength }} characters.',
        passwordNotEqual: `The passwords doesn't match.`,
        togglePasswordVisibility: 'toggle password visibility'
      },
      reviewStep: {
        audio: SignUpReview,
        greatRememberThis: 'Great! You must remember this. Write it down!',
        username: 'Username:',
        password: 'Password:',
        iveWrittenItDown: `I have written it down`
      }
    },
    signIn: {
      usernameStep: {
        audio: SignInUsername,
        enterYourUsername: 'Enter your username to log in.',
        enterYourUsernameInput: 'Enter your username',
        notPlayedBefore: 'Not played before?',
        createNewUser: 'Create new user',
        usernameError: 'This username doesn’t exist!'
      },
      passwordStep: {
        audio: SignInPassword,
        youMustEnterYourPassword: 'You must also enter your password.',
        passwordWithMinCharacter:
          'Password with {{ minPasswordLength }} characters',
        passwordError: 'Username or password did not match a valid account.'
      }
    }
  },
  municipality: {
    getStartedWithFeide: 'Get started with Feide for free'
  }
}
