import CheckUser from './auth/CheckUser'
import Register from './auth/Register'
import Login from './auth/Login'
import AccountPlayer from './auth/Account.Player'
import ValidateUsername from './auth/ValidateUsername'
import Logout from './auth/Logout'
import CheckUserProfile from './auth/CheckUserProfile'

import UserInfo from './user/Info'

export const AuthServices = {
  CheckUser,
  Register,
  Login,
  AccountPlayer,
  ValidateUsername,
  Logout,
  CheckUserProfile
}

export const UserServices = {
  UserInfo
}
export type {
  AuthAccountPlayerPayloadTypes,
  AuthAccountPlayerResponseTypes,
  AuthAccountPlayerEditPayloadTypes,
  AuthAccountLocationStateTypes,
  AuthAccountTypes
} from './auth/Account.Player.types'

export type {
  AuthRegisterPayloadTypes,
  AuthRegisterResponseTypes
} from './auth/Register.types'

export type { AuthLoginPayloadTypes } from './auth/Login.types'
