class LocalStorageService {
  static setItem<T>(key: string, value: T): void {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      throw new Error('Failed to set item in localStorage')
    }
  }

  static getItem<T>(key: string): T | null {
    try {
      const retrievedData = localStorage.getItem(key)
      if (!retrievedData) {
        throw new Error('Item not found in localStorage')
      }
      return JSON.parse(retrievedData) as T
    } catch (error) {
      throw new Error('Failed to get item from localStorage')
    }
  }

  static removeItem(key: string): void {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      throw new Error('Failed to remove item from localStorage')
    }
  }
}

export default LocalStorageService
