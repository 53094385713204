import { api } from 'services/api/api'
import LocalStorageService from 'utils/localStorageService'

const getLogout = async (token: string) => {
  try {
    const response = await api.get(`/auth/logout`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    const { shouldRedirect, redirectUrl } = response.data

    if (shouldRedirect && redirectUrl) {
      LocalStorageService.removeItem('session')
      window.location.href = redirectUrl
    } else {
      const sessionData = LocalStorageService.getItem<{
        token: string
      }>('session')

      if (sessionData) {
        LocalStorageService.removeItem('session')
        window.location.href = '/'
      }
    }
    return null
  } catch (error) {
    return error
  }
}
export default {
  get: getLogout
}
