import { GeneralLocaleTypes } from '../locales.types'

const generalLocaleEs: GeneralLocaleTypes = {
  username: 'Nombre de usuario',
  imageAlt: {},
  status: {
    error: {
      somethingWentWrong: 'Algo salió mal',
      sessionError:
        'Imposible verificar sesión, por favor inténtalo de nuevo más tarde...',
      failedToLogin:
        'Error al iniciar sesión. Por favor, inténtelo de nuevo más tarde.',
      underMaintenance:
        'En mantenimiento. Por favor, inténtelo de nuevo más tarde.',
      failWriteToLocalStorage:
        'No se pueden guardar datos. Verifique su configuración de privacidad o el espacio disponible en disco e inténtelo nuevamente.',
      sessionExpired: 'Sesión expirada. Por favor inicie sesión nuevamente.'
    }
  },
  actions: {
    back: 'Atrás',
    cancel: 'Cancelar',
    moveOn: 'Continuar',
    edit: 'Editar',
    delete: 'Eliminar',
    remove: 'Remover',
    next: 'Siguiente',
    save: 'Guardar {{ addOn }}',
    update: 'Actualizar {{ addOn }}',
    select: 'Seleccionar',
    confirm: 'Confirmar',
    revoke: 'Revocar',
    close: 'Cerrar'
  },
  or: 'o'
}
export default generalLocaleEs
